export const navItems = [
  {
    to: "",
    items: [
      { name: "Solidity Shield", to: "/solidity-shield" },
      { name: "Secure Watch", to: "/secure-watch" },
      { name: "Secure Trace", to: "/secure-trace" },
      { name: "Secure Pad", to: "/secure-pad" },
    ],
    label: "Product",
  },
  {
    to: "",
    items: [
      { name: "DApp Development", to: "/dapp-development" },
      { name: "Smart Contract Audit", to: "/smart-contract-audit" },
      { name: "Dapp Security Audit", to: "/dapp-security-audit" },
      { name: "Token Audit", to: "/token-audit" },
      { name: "Web3 KYC", to: "/web3-kyc" },
      { name: "Web3 Security", to: "/web3-security" },
      { name: "Blockchain Forensic", to: "/blockchain-forensic" },
      { name: "RWA Audit", to: "/rwa-audit" },
      { name: "Crypto Compliance & AML", to: "/crypto-compliance-aml" },
      {
        name: "Decentralized Identity (DID)",
        to: "/decentralized-identity-did",
      },
      { name: "NFTs Development", to: "/nfts-development" },
      { name: "DeFi Development", to: "/defi-development" },
      { name: "LevelUp Academy", to: "/levelup-academy" },
    ],
    label: "Services",
  },
  {
    to: "",
    items: [
      { name: "Blogs", to: "/blog" },
      { name: "About Us", to: "/about" },
      { name: "Media", to: "/media" },
    ],
    label: "Resources",
  },
  {
    to: "/solidity-shield-scan/pricing",
    items: [],
    label: "Pricing",
  },
];
