import CustomButton from "../../components/common/CustomButton";
import "./VulnerabilityScan.css";

const VulnerabilityScanCard = ({ header, image, description, onClick }) => {
  return (
    <div className="sss-vul-scan-card">
      <div className="sss-vul-scan-card-header">
        <div className="sss-vul-scan-card-header-left">{header}</div>
        <div className="sss-vul-scan-card-header-right">
          <img
            src="/assets/images/solidity-shield-scan/vul-scan-card-details.svg"
            alt=""
          />
        </div>
      </div>
      <div className="sss-vul-scan-card-body">
        <div className="sss-vul-scan-card-image-container">
          <img src={image} alt="" className="sss-vul-scan-card-image" />
        </div>
      </div>
      <div className="sss-vul-scan-card-footer">
        <div
          className="sss-vul-scan-card-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="sss-vul-scan-card-footer-button">
          <div className="sss-vul-scan-card-button">
            <CustomButton
              text={"Scan"}
              className={
                "px-10 py-2 border border-tertiary bg-tertiary rounded-lg active:bg-white active:text-black"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const VulnerabilityScan = () => {
  return (
    <div className="sss-vul-scan-screen-container">
      <div className="sss-vul-scan-screen">
        <div className="sss-vul-scan-header">
          <div className="">Vulnerability Scans</div>
        </div>
        <div className="sss-vul-scan-body">
          <div className="sss-vul-scan-cards">
            <VulnerabilityScanCard
              header={"Scan Type"}
              image={""}
              description={
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.<br>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
              }
              onClick={() => {}}
            />
            <VulnerabilityScanCard
              header={"Scan Type"}
              image={""}
              description={
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.<br>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
              }
              onClick={() => {}}
            />
            <VulnerabilityScanCard
              header={"Scan Type"}
              image={""}
              description={
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.<br>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
              }
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VulnerabilityScan;
