const teamData = [
  {
    name: "Abhishek Singh",
    designation: "Co-Founder & CEO",
    image: "/assets/images/team/abhishek.png",
    twitter: "",
    linkedin: "",
  },
  {
    name: "Himanshu Gautam",
    designation: "Co-Founder, CTO",
    image: "/assets/images/team/himanshu.png",
    twitter: "",
    linkedin: "",
  },
  {
    name: "Andrew E",
    designation: "Creative Head",
    image: "/assets/images/team/andrew.png",
    twitter: "",
    linkedin: "",
  },
  {
    name: "Dinesh",
    designation: "Head of Finance",
    image: "/assets/images/team/dinesh.png",
    twitter: "",
    linkedin: "",
  },
  {
    name: "Akshit Mokani",
    designation: "Manager Community",
    image: "/assets/images/team/akshit.png",
    twitter: "",
    linkedin: "",
  },
  {
    name: "Thejaswi PB",
    designation: "Head HR",
    image: "/assets/images/team/thejaswi.png",
    twitter: "",
    linkedin: "",
  },
  {
    name: "Aryaman Tiwari",
    designation: "Manager Legal",
    image: "/assets/images/team/aryaman.png",
    twitter: "",
    linkedin: "",
  },
  {
    name: "Manish Kumar",
    designation: "Growth & Delivery Advisor",
    image: "/assets/images/team/manish.png",
    twitter: "",
    linkedin: "",
  },
];

export const companyCulture = [
  "Honesty",
  "Modesty",
  "Pushing Boundaries",
  "Transparency",
  "Ethical Standards",
  "Hungry for Exploration",
];

export default teamData;
