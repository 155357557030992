import { faEnvelope, faMouse, faPlaneDeparture, faSliders, faVideo, faVoicemail } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const faqsData = [
  [
    {
      id: 0,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 1,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 2,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 3,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 4,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
  ],
  [
    {
      id: 0,
      q: "Plan 2, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 1,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 2,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 3,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 4,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
  ],
  [
    {
      id: 0,
      q: "Plan 3, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 1,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 2,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 3,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 4,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
  ],
  [
    {
      id: 0,
      q: "Plan 4, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 1,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 2,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 3,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 4,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
  ],
  [
    {
      id: 0,
      q: "Plan 4, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 1,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 2,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 3,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
    {
      id: 4,
      q: "Plan, execute, and track projects of any size",
      a: "Easily assign tasks and prioritize what's most important to your team. Track your team's progress, set project timelines, and manage their work all in one place.",
    },
  ],
];

export const reviews = [
  {
    id: 0,
    name: "Adlinda Paw",
    designation: "Engineering Head",
    testimonial:
      '"Working with Bilqis has been a game-changer for our business.  Their strategic insights and innovative solutions helped us streamline  our operations and achieve remarkable growth"',
  },
  {
    id: 1,
    name: "Adlinda Paw",
    designation: "Engineering Head 2",
    testimonial:
      '"Working with Bilqis has been a game-changer for our business.  Their strategic insights and innovative solutions helped us streamline  our operations and achieve remarkable growth"',
  },
  {
    id: 2,
    name: "Adlinda Paw",
    designation: "Engineering Head 3",
    testimonial:
      '"Working with Bilqis has been a game-changer for our business.  Their strategic insights and innovative solutions helped us streamline  our operations and achieve remarkable growth"',
  },
  {
    id: 3,
    name: "Adlinda Paw",
    designation: "Engineering Head 4",
    testimonial:
      '"Working with Bilqis has been a game-changer for our business.  Their strategic insights and innovative solutions helped us streamline  our operations and achieve remarkable growth"',
  },
];

export const features = [
  {
    icon: faEnvelope,
    iconBackgroundColor: "#F4CAE8",
    header: "Lorem Ipsum",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
  },
  {
    icon: faMouse,
    iconBackgroundColor: "#B5E4CA",
    header: "Lorem Ipsum",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
  },
  {
    icon: faVideo,
    iconBackgroundColor: "#FFBC99",
    header: "Lorem Ipsum",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
  },
  {
    icon: faSliders,
    iconBackgroundColor: "#FFD88D",
    header: "Lorem Ipsum",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
  },
  {
    icon: faVoicemail,
    iconBackgroundColor: "#CABDFF",
    header: "Lorem Ipsum",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
  },
  {
    icon: faPlaneDeparture,
    iconBackgroundColor: "#B1E5FC",
    header: "Lorem Ipsum",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
  },
];

export const howItWorksData = [
  {
    image: "/assets/images/product-1-how-it-works-1.svg",
    imageAlt: "",
    header: "Workflow builder",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: "/assets/images/product-1-how-it-works-2.svg",
    imageAlt: "",
    header: "Trigger Actions",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: "/assets/images/product-1-how-it-works-3.svg",
    imageAlt: "",
    header: "Task Routing",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: "/assets/images/product-1-how-it-works-4.svg",
    imageAlt: "",
    header: "Workflow Monitoring",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: "/assets/images/product-1-how-it-works-5.svg",
    imageAlt: "",
    header: "Conditional Logic",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: "/assets/images/product-1-how-it-works-6.svg",
    imageAlt: "",
    header: "Integration third-party",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];
